import "./WeatherCardComponent.scss";
import DayWeatherComponent from "./DayWeatherComponent";

export default function WeatherInfoComponent({ weatherData, location }) {
  return (
    <div className="mb-weather-info-container my-4">
      {weatherData?.map((day) => {
        const date = new Date(day.dt * 1000);
        const dayOfWeek = date?.getDay();
        let dayName;
        switch (dayOfWeek) {
          case 0:
            dayName = "Sunday";
            break;
          case 1:
            dayName = "Monday";
            break;
          case 2:
            dayName = "Tuesday";
            break;
          case 3:
            dayName = "Wednesday";
            break;
          case 4:
            dayName = "Thursday";
            break;
          case 5:
            dayName = "Friday";
            break;
          case 6:
            dayName = "Saturday";
            break;
          default:
            dayName = undefined;
        }
        const shortDateString = `${dayName.substring(0, 3)} ${date?.getDate()}`;
        return (
          <DayWeatherComponent
            key={day.dt} // Adding key prop to avoid React warning
            date={shortDateString}
            temp={day.temp.day}
            wind={day.wind_speed}
            icon={day.weather[0].icon}
          />
        );
      })}
    </div>
  );
}
