import "./DayWeatherComponent.scss";

export default function WindComponent({ windSpeed }) {
  return (
    <div className="mb-wind d-flex align-items-center pt-2">
      <img src="/images/wind-icon.png" alt="wind icon" />
      <span className="">{Math.round(windSpeed)} mph</span>
    </div>
  );
}
