import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./variables.scss";
import HeaderComponent from "./HeaderComponent";
import WeatherCardComponent from "./WeatherCardComponent";

function App() {
  const [homeWeatherData, setHomeWeatherData] = useState(null);
  const [workWeatherData, setWorkWeatherData] = useState(null);
  const apiKey = "f2a587d87e374534aac66b5a69e4d23a";

  useEffect(() => {
    async function getLocation(locationName) {
      const homeLocationURL = `https://api.openweathermap.org/geo/1.0/direct?q=${locationName},GB&limit=5&appid=${apiKey}`;
      const response = await fetch(homeLocationURL);
      const locations = await response.json();
      return locations;
    }

    async function getWeather(locationName) {
      const locations = await getLocation(locationName);
      const location = locations[0];

      const weatherURL = `https://api.openweathermap.org/data/3.0/onecall?lat=${location.lat}&lon=${location.lon}&units=metric&appid=${apiKey}`;
      const response = await fetch(weatherURL);
      const weather = await response.json();
      return weather;
    }

    async function fetchData() {
      try {
        const homeWeather = await getWeather("Warrington");
        setHomeWeatherData(homeWeather);

        const workWeather = await getWeather("London");
        setWorkWeatherData(workWeather);
        console.log("homeWeather", homeWeather);
        console.log("workWeather", workWeather);
      } catch (error) {
        alert("Error fetching data:", error);
        // Handle error (e.g., show a message to the user)
      }
    }

    fetchData();
  }, []);

  return (
    <div className="mb-page bg-primary">
      <HeaderComponent />
      <Container fluid style={{ maxWidth: "1200px" }} className="pb-5">
        <Row className="mb-4">
          <Col>
            <WeatherCardComponent
              homeWeather={homeWeatherData}
              workWeather={workWeatherData}
              location="Warrington"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <WeatherCardComponent
              homeWeather={homeWeatherData}
              workWeather={workWeatherData}
              location="London"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
