import "./DayWeatherComponent.scss";
import WindComponent from "./WindComponent";

export default function DayWeatherComponent({ date, temp, icon, wind }) {
  return (
    <div>
      <div className="mb-icon-group">
        <img
          src={`https://openweathermap.org/img/wn/${icon}@2x.png`}
          alt="weather icon"
        />
      </div>
      <div className="text-center mt-2 border-bottom border-grey-10 pb-2">
        <div>{date}</div>
        <div className="fw-bold fs-5">{`${Math.round(temp)}°`}</div>
      </div>
      <div className="">
        <WindComponent windSpeed={wind} />
      </div>
    </div>
  );
}
