import { useMemo } from "react";
import "./WeatherCardComponent.scss";
import WeatherInfoComponent from "./WeatherInfoComponent";
import { Container, Col, Row } from "react-bootstrap";

export default function WeatherCardComponent(props) {
  const _weatherData = useMemo(() => {
    if (props.location === "Warrington") {
      return props.homeWeather;
    } else if (props.location === "London") {
      return props.workWeather;
    }
  }, [props.homeWeather, props.location, props.workWeather]);

  const imgSrc = `/images/${props.location}.jpeg`;

  const dailyData = _weatherData?.daily;

  const nextTueWedThu =
    dailyData &&
    dailyData
      .filter((weather) => {
        const date = new Date(weather.dt * 1000); // Adjust for timezone offset
        const today = new Date(); // Current date

        // Calculate the day of the week for the weather date
        const dayOfWeek = date.getUTCDay();

        // Calculate the difference in days between today and the weather date
        const daysDifference = Math.floor(
          (date - today) / (24 * 60 * 60 * 1000)
        );

        // Check if the day of the week matches Tuesday, Wednesday, or Thursday
        // and if it's the next occurrence of that day
        return (
          (dayOfWeek === 2 && daysDifference >= 0 && daysDifference <= 6) || // Tuesday
          (dayOfWeek === 3 && daysDifference >= 0 && daysDifference <= 6) || // Wednesday
          (dayOfWeek === 4 && daysDifference >= 0 && daysDifference <= 6) // Thursday
        );
      })
      .sort((a, b) => {
        const dayOfWeekMap = { Tuesday: 1, Wednesday: 2, Thursday: 3 };
        return dayOfWeekMap[a.day] - dayOfWeekMap[b.day];
      });

  console.log(nextTueWedThu);

  const currentWeather = useMemo(() => {
    const currentTemp = Math.round(_weatherData?.current?.temp);
    const currentDescription = _weatherData?.current?.weather[0]?.description;

    return `currently ${currentTemp}° (${currentDescription})`;
  }, [_weatherData]);

  return (
    <div className={`mb-weather-card ${props.location}`}>
      <Container fluid className="px-0">
        <Row className="gx-0">
          <Col md={7} className="p-3 p-md-4 pb-0 pb-md-0">
            <div className="mb-location-title fw-bold fs-2 d-flex align-items-center">
              <span className="border-end border-2 border-primary pe-4 me-4">
                {props.location}
              </span>
              <span className="mb-location-description fw-normal">
                {currentWeather}
              </span>
            </div>
            <div className="mb-location-info">
              <WeatherInfoComponent
                weatherData={nextTueWedThu}
                location={props.location}
              />
            </div>
          </Col>
          <Col md={5} className="p-2">
            <img
              src={imgSrc}
              className="mb-location-image"
              alt={props.location}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
