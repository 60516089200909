export default function HeaderComponent() {
  return (
    <header className="py-4 bg-primary d-flex justify-content-center align-items-center">
      <img
        src={"./images/logo-white.svg"}
        alt="logo"
        className="d-block"
        width={"200px"}
      />
    </header>
  );
}
